<template>
  <div>
    <CRow v-if="view == 'table'">
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de clientes"
          icon="fas fa-users"
          btn_name="cliente"
          :size="'md'"
          :button_new="false"
          :actions="true"
          :withActions="'6%'"
          :myButtons="myButtons"
          @mtd_set_sale="mtd_set_sale"
        />
      </CCol>
    </CRow>
    <CRow v-else>
      <CCol lg="3" sm="3" md="12" xs="12" class="text-left">
        <CButton
          size="sm"
          color="warning"
          v-c-tooltip.hover="{
            content: 'Regresar',
          }"
          @click="mtd_return_table"
        >
          <i class="fas fa-angle-left"></i> Regresar
        </CButton>
      </CCol>
      <CCol lg="3" sm="3" md="12" xs="12" class="">
        <CInput
          horizontal
          label="Fecha Pago"
          size="sm"
          v-model="cabecera.date"
          type="date"
        ></CInput>
      </CCol>
      <CCol lg="2" sm="2" md="12" xs="12" class="">
        <CInput
          horizontal
          label="Inicial"
          size="sm"
          placeholder="Inicial"
          v-model="cabecera.initial"
          v-on:keypress="isNumber($event)"
        ></CInput>
      </CCol>
      <CCol lg="4" sm="4" md="12" xs="12" class="text-rigth">
        <CButton
          size="sm"
          color="info"
          v-c-tooltip.hover="{
            content: 'Registrar venta',
          }"
          @click="mtd_save"
          :disabled="cp_disabled_save"
        >
          <i class="fas fa-save"></i> Registrar
        </CButton>
      </CCol>
      <CCol class="mt-2" lg="12" sm="12" md="12" xs="12">
        <CCard>
          <CCardHeader>
            <strong> DETALLE</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="12" md="12" sm="12" xs="12">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Producto</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in details" :key="index">
                      <td width="5%">{{ index + 1 }}</td>
                      <td>{{ item.product_name }}</td>
                      <td width="10%">{{ item.quantity }}</td>
                      <td width="10%">
                        <CInput
                          size="sm"
                          v-model="item.price"
                          placeholder="Precio"
                          v-on:keypress="isNumber($event)"
                          @blur="mtd_setSubtotal(index)"
                        ></CInput>
                      </td>
                      <td width="10%">{{ item.subtotal }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4" class="text-right">
                        <strong>TOTAL:</strong>
                      </td>
                      <td>
                        <strong> {{ cp_total }}</strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";
import CTableWrapper from "../../components/shared/datatable/Table.vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "document", label: "Documento", _style: "width:20%;" },
  { key: "client", label: "Cliente", _style: "width:20%;" },
];

export default {
  components: { vSelect, CTableWrapper },
  name: "v-proccess-client",
  data() {
    return {
      prefix: "sale/client",
      view: "table",
      fields,
      data: [],
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Registrar venta",
          action: "mtd_set_sale",
          icon: "fas fa-comment-dollar",
        },
      ],
      details: [],
      cabecera: {
        id:"",
        date: null,
        initial: "",
        total: 0,
      },
    };
  },
  computed: {
    cp_total: function () {
      let tot = 0;
      this.details.forEach((element) => {
        tot += parseFloat(element.subtotal);
      });
      this.cabecera.total = parseFloat(tot).toFixed(2);
      return parseFloat(tot).toFixed(2);
    },
    cp_disabled_save: function () {
      if (this.cabecera.date != null && this.cabecera.total > 0) return false;
      return true;
    },
  },
  created() {
    this.mtd_getData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getData: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_set_sale: function (item) {
      this.view = "data";
      let det = [];
      this.cabecera.id = item.Id;
      item.detail.forEach((element) => {
        det.push({
          id_detalle: element.id,
          product_name: element.product.name,
          product_id: element.product.id,
          quantity: element.quantity,
          price: element.price,
          subtotal: 0,
        });
      });
      this.details = det;
    },
    mtd_return_table: function () {
      this.view = "table";
      this.details = [];
    },
    mtd_setSubtotal: function (pos) {
      let price = parseFloat(this.details[pos].price);
      let cant = parseFloat(this.details[pos].quantity);
      this.details[pos].subtotal = parseFloat(price * cant).toFixed(2);
    },
    mtd_save: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix+"/store",
        token: this.$store.getters.get__token,
        params:{
            cabecera: this.cabecera,
            detail: this.details
        }
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "VENTA REGISTRADA CORRECTAMENTE!!",
            });
            this.details=[];
            this.cabecera = {
                id:"",
                date: null,
                initial: "",
                total: 0,
            };
            this.view= "table";
            this.mtd_getData();
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>